<template>
  <Layout />
</template>
<script>
import Layout from '@/layout'
export default {
  name: 'App',
  components: {Layout}
}
</script>
<style lang="less">
*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.layout-center {
  width: 55%;
  margin: 0 auto;
}
</style>