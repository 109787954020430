<template>
    <div class="cases">
        <div class="carousels">
            <div class="arrow-left" @click="onPrev()">
                <img src="../assets/images/arrow.png" alt="">
            </div>
            <div class="carousel">
                <img :src="require(`../assets/images/cases/${getFolder}/00${getImageSrc}.png`)" alt="">
            </div>
            <div class="arrow-right" @click="onNext()">
                <img src="../assets/images/arrow.png" alt="">
            </div>
        </div>
        <div class="brief">
            <h5>{{ getTitle }}</h5>
            <p>
                {{ getBrief }}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Cases-Page',
    data() {
        return {
            carousels: [
                {
                    title: '家庭漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '家庭漏水',
                    imageSrc: '1'
                },
                {
                    title: '家庭漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '家庭漏水',
                    imageSrc: '2'
                },
                {
                    title: '家庭漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '家庭漏水',
                    imageSrc: '3'
                },
                {
                    title: '家庭漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '家庭漏水',
                    imageSrc: '4'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '1'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '2'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '3'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '4'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '5'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '6'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '7'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '8'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '9'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '10'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '11'
                },
                {
                    title: '埋地管网漏水检测',
                    brief: '    家庭中的自来水管一般漏量小，多渗漏，探测难度大是家庭管道漏水的特点，我公司长期关注国内外最新技术，引进了多种进口设备，除传统声学探漏技术以外还开发出多种适用于家庭的新型探漏技术，是全国少数可以进行家庭漏水准确定位的公司。我们可以将家庭漏水的漏点准确定位到一个笔记本电脑大小的范围，不仅避免了地面、墙面大面积开挖带来的生活干扰，还为用户大大的节省了维修成本。',
                    folder: '埋地管网',
                    imageSrc: '12'
                },
            ],
            carouselIndex: 0,
            title: '',
            brief: '',
            folder: '',
            imageSrc: ''
        }
    },
    computed: {
        getTitle: function () {
            return this.carousels[this.carouselIndex].title;
        },
        getBrief: function () {
            return this.carousels[this.carouselIndex].brief;
        },
        getFolder: function () {
            return this.carousels[this.carouselIndex].folder;
        },
        getImageSrc: function () {
            return this.carousels[this.carouselIndex].imageSrc;
        }
    },
    methods: {
        onPrev() {
            const {carouselIndex, carousels} = this;
            const _carouselIndex = carouselIndex - 1;
            if (_carouselIndex < 0) {
                this.carouselIndex = carousels.length - 1;
            } else {
                this.carouselIndex = _carouselIndex;
            }
        },
        onNext() {
            const {carouselIndex, carousels} = this;
            const _carouselIndex = carouselIndex + 1;
            if (_carouselIndex >= carousels.length) {
                this.carouselIndex = 0
            } else {
                this.carouselIndex = _carouselIndex;
            }
        }
    }
}
</script>
<style lang="less" scoped>
.cases {
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  .carousels {
    display: flex;
    align-items: center;
    margin-right: 40px;

    .carousel {
      width: 249px;
      height: 388px;
      border-radius: 13px;
      overflow: hidden;
      margin: 0 22px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-size: 0;
      }
    }

    .arrow-left,
    .arrow-right {
      img {
        width: 30px;
        cursor: pointer;
      }
    }

    .arrow-right img {
      transform: rotate(180deg);
    }
  }

  .brief {
    flex: 1;
    overflow: hidden;

    h5 {
      font-size: 24px;
      color: #010101;
      margin-bottom: 13px;
    }

    p {
      font-size: 18px;
      color: #020202;
      line-height: 1.5;
      text-indent: 26px;
    }
  }
}
</style>