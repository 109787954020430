<template>
  <div class="company-intro">
    <div class="logo-banner">

    </div>
    <div class="brief">
      <h5>公司简介</h5>
      <p> 我公司是一家为用水单位提供地下管道漏水自动监测报警、
        漏水检测定位、管道维修、节水设施改造一条龙服务的创新型
        科技公司。我们专注于为机关团体、企业、居民小区等各类型
        用水单位提供解决水耗管理难、用水成本高等问题的一站式服
        务，通过在线监测和数据分析的方法，帮助客户实现节水降耗
        的目标。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Company-Intro'
}
</script>
<style lang="less" scoped>
.company-intro {
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.logo-banner {
  width: 207px;
  height: 312px;
  background: #fdfdfd;
  margin-right: 145px;
  background: url('../assets/images/logo1.png') no-repeat center bottom / cover;
}

.brief {
  flex: 1;
  overflow: hidden;
  margin-top: -30px;

  h5 {
    font-size: 26px;
    color: #020202;
    margin-bottom: 33px;
  }

  p {
    text-indent: 30px;
    font-size: 18px;
  }
}
</style>