<template>
  <div class="equipment-intro">
    <div class="equipment-main">
      <div class="equipement-left">
        <div class="box-3">
          <img :src="require(`../assets/images/equipment/${getItem1}.png`)"/>
          <p>{{ getItem1 }}</p>
        </div>
      </div>
      <div class="equipement-right">
        <div class="box-2">
          <img :src="require(`../assets/images/equipment/${getItem2}.png`)"/>
          <p>{{ getItem2 }}</p>
        </div>
        <div class="box-2">
          <img :src="require(`../assets/images/equipment/${getItem3}.png`)"/>
          <p>{{ getItem3 }}</p>
        </div>
        <div class="box-2">
          <img :src="require(`../assets/images/equipment/${getItem4}.png`)"/>
          <p>{{ getItem4 }}</p>
        </div>
      </div>
    </div>
    <div class="equipement-bottom">
      <div class="btn" :class="getSelected1? 'selected':'no-select'" style="margin-left: 90px"
           @click="onClick(0)"></div>
      <div class="btn" :class="getSelected2? 'selected':'no-select'" @click="onClick(1)"></div>
      <div class="btn" :class="getSelected3? 'selected':'no-select'" @click="onClick(2)"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Equipment-Intro',
  data() {
    return {
      carousels: [
        {
          item1: "机械秒表",
          item2: "寻管仪",
          item3: "超声波流量计",
          item4: "地下潜式潜望镜"
        },
        {
          item1: "机械秒表",
          item2: "氦气检测仪",
          item3: "漏水检测仪",
          item4: "红外探测仪"
        },
        {
          item1: "机械秒表",
          item2: "相关仪",
          item3: "热成像仪",
          item4: "气体追踪仪"
        }
      ],
      carouselIndex: 0,
      item1: '',
      item2: '',
      item3: '',
      item4: '',
      isSelected1: false,
      isSelected2: false,
      isSelected3: false,
    }
  },
  computed: {
    getItem1: function () {
      const {carouselIndex, carousels} = this;
      return carousels[carouselIndex].item1;
    },
    getItem2: function () {
      const {carouselIndex, carousels} = this;
      return carousels[carouselIndex].item2;
    },
    getItem3: function () {
      const {carouselIndex, carousels} = this;
      return carousels[carouselIndex].item3;
    },
    getItem4: function () {
      const {carouselIndex, carousels} = this;
      return carousels[carouselIndex].item4;
    },
    getSelected1: function () {
      return this.carouselIndex === 0;
    },
    getSelected2: function () {
      return this.carouselIndex === 1;
    },
    getSelected3: function () {
      return this.carouselIndex === 2;
    }
  },
  methods: {
    onClick(index) {
      this.carouselIndex = index;
    }
  }
}
</script>

<style lang="less" scoped>

.equipment-intro {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .equipment-main {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 130px;

    .equipement-left {
      width: 50%;
      height: 400px;
      display: flex;
      padding-left: 50px;

      .box-3 {
        width: 434px;
        height: 340px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 13px;
        margin: 35px 40px;

        img {
          width: 100%;
          height: 90%;
          object-fit: cover;
          font-size: 0;
          margin-top: 0;
          border-radius: 13px;

        }

        p {
          padding-bottom: 40px;
          font-size: 17px;
          color: #020202;
          line-height: 1.5;
          text-indent: 26px;
        }
      }


    }

    .equipement-right {
      width: 50%;
      height: 388px;

      .box-2 {
        height: 90px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 13px;
        margin: 35px 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          flex: 1;
          width: 201px;
          height: 90px;
          border-radius: 13px;
        }

        p {
          flex: 1;
          font-size: 17px;
          color: #020202;
          line-height: 1.5;
          text-indent: 26px;
          text-align: left;

        }
      }
    }
  }

  .equipement-bottom {
    flex: 1;
    display: flex;

    .btn {
      width: 23px;
      height: 25px;
      margin: 5px;
      border: NaNpx solid #FFFFFF;
      border-radius: 50%;
    }

    .no-select {
      background: #FFFFFF;
    }

    .selected {
      background: #009944;
    }
  }
}

</style>