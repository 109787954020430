<template>
    <div class="home">
        <div class="carousel">
            <img src="../assets/images/manager.png">
        </div>
        <div class="brief">
            <div class="box-1">
                <img src="../assets/images/logo.png">
                <h5>云哨-用水管家</h5>
            </div>
            <p>用水台账分析 给排水总平图上传和下载 历史漏点信息<br>
                历史用水量和计划用水量抄报和查看</p>
        </div>
        <div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home-Page'
}
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  .carousel {
    flex: 1;
    border-radius: 13px;
    overflow: hidden;
    margin: 0 22px;
    text-align: center;

    img {
      width: 80%;
      object-fit: cover;
      font-size: 0;
        margin-left: -40px;
    }
  }

  .brief {
    flex: 1;
    overflow: hidden;

    .box-1 {
      display: flex;

      img {
        width: 44px;
        height: 63px;
        margin-left: 30px;
        margin-right: 30px;
        font-size: 0;
      }

      h5 {
        height: 33px;
        font-size: 30px;
        font-weight: normal;
        color: #FEFEFE;
        margin-bottom: 13px;
        text-align: center;
      }
    }

    p {
      margin-top: 40px;
      padding-left: 30px;
      font-size: 17px;
      color: #fff;
      line-height: 1.5;
    }
  }
}
</style>