<template>
    <div class="product-intro">
        <div class="product-mode" v-show="!isViewMore">
            <div class="product-img">
                <img src="../assets/images/product/产品.png" alt="">
                <p class="view-more-trigger" @click.stop="isViewMore = true">了解更多</p>
            </div>
            <div class="brief">
                <h5>漏水在线监测系统：</h5>
                <p>通过安装在用水设备上的传感器，对客户用水进行实时监测和数据采集，
                    建立全面、准确的用水数据库，同时提供实时警报、数据报表等功能，帮助
                    客户实现对水耗的全面监测和管理。</p>
            </div>
        </div>
        <div class="view-more" v-show="isViewMore">
            <img class="one" src="../assets/images/product/产品介绍-2.png" alt="">
            <img class="two" src="../assets/images/product/产品介绍-3.png" alt="">
            <div class="three">
                <img src="../assets/images/product/产品介绍-1.png" alt="">
                <p class="text text-1">给排水总平图<br />的上传和下载</p>
                <p class="text text-2">用水台账分析，自动计<br />算出用水偏高还是正常</p>
                <p class="text text-3">当前用水量、历史用水量<br />和计划用水量抄报和查看</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Product-Intro',
    data() {
        return {
            isViewMore: false
        }
    }
}
</script>
<style lang="less" scoped>
.product-intro {
    height: 100%;
    // overflow: hidden;
    display: flex;
    align-items: center;
}

.product-mode {
    display: flex;
    // align-items: center;

    .product-img {
        img {
            width: 196px;
        }
        position: relative;
        margin-right: 119px;
        .view-more-trigger {
            cursor: pointer;
            position: absolute;
            bottom: -30px;
            left: 50%;
            transform: translate(-80%, 100%);
            font-size: 15px;
            color: #fefdfd;
        }
    }
    .brief {
        color: #020202;
        h5 {
            font-size: 24px;
            margin-bottom: 15px;
        }
        p {
            text-indent: 30px;
            font-size: 18px;
        }
    }
}

.view-more {
    display: flex;
    align-items: center;
    position: relative;

    .one,
    .two {
        width: 150px;
        margin-right: 70px;
        align-self: flex-end;
    }

    .three {
        width: calc(100% - 440px);
        position: relative;

        // overflow: hidden;
        img {
            width: 100%;
        }

        .text {
            position: absolute;
            font-size: 15px;
            color: #fdfdfd;
        }

        .text-1 {
            left: 0;
            transform: translateX(-20%);
            top: 40%;
        }

        .text-2 {
            right: 0;
            transform: translateX(35%);
            top: 30%;
        }

        .text-3 {
            bottom: 0;
            right: 0;
            transform: translateY(100%);
        }
    }
}
</style>