<template>
    <div id="layout">
        <div class="box">
            <Navs/>
        </div>
        <div class="main-view layout-center">
            <RouterView/>
        </div>
        <div class="box">
            <Footer/>
        </div>
    </div>
</template>
<script>
import Navs from './components/navs';
import Footer from './components/footer.vue';

export default {
    name: 'Layout-Component',
    components: {
        Navs,
        Footer
    }
}
</script>
<style lang="less">
#layout {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url('../assets/images/background1.png') no-repeat center bottom / cover;
  display: flex;
  flex-direction: column;
}

.main-view {
  flex: 1;
  // overflow: hidden;
}

.box {
    background-color: rgba(255, 255, 255, 0.5);
}
</style>