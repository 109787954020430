<template>
  <div class="contact">
    <div class="qrcodes">
      <div class="qrcode">
        <div class="img1"></div>
        <span class="phone">谢先生  13348802990 </span>
      </div>
      <div class="qrcode">
        <div class="img2"></div>
        <span class="phone">罗先生  18980021979 </span>
      </div>
    </div>
    <div class="address">公司地址：成都市金牛区永陵路9号H座1楼</div>
  </div>
</template>
<script>
export default {
  name: 'Contact-Page'
}
</script>
<style lang="less" scoped>
.contact {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px 0;
  height: 90%;

  .qrcodes {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .qrcode {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:first-child {
        margin-right: 140px;
      }

      .img1 {
        width: 120px;
        height: 120px;
        font-size: 0;
        background: #fdfdfd;
        margin-bottom: 15px;
        background: url('../assets/images/qrcode1.png') no-repeat center bottom / cover;
      }

      .img2 {
        width: 120px;
        height: 120px;
        font-size: 0;
        background: #fdfdfd;
        margin-bottom: 15px;
        background: url('../assets/images/qrcode2.png') no-repeat center bottom / cover;
      }

      .phone {
        font-size: 15px;
        color: #fdfdfd;
      }
    }
  }

  .address {
    font-size: 15px;
    color: #fdfdfd;
    text-align: center;
  }
}
</style>