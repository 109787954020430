<template>
  <div class="navs">
    <div class="layout-center navs-content">
      <a class="logo" @click.stop="onLink('/')">
        <img src="../../assets/images/logo.png" alt="">
      </a>
      <div class="links">
        <div class="link-item"
             @click.stop="onLink(link.path)"
             :class="[{active: link.path === activeLink}]"
             v-for="link of links" :key="link.path">
          <span class="route">{{ link.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {routes} from '@/router'

export default {
  name: 'Navs-Component',
  data() {
    return {
      routes
    }
  },
  computed: {
    links() {
      return this.routes.filter(route => route.name)
    },
    activeLink() {
      return this.$route.path
    }
  },
  mounted() {
    console.log(routes)
  },
  methods: {
    onLink(link) {
      console.log(link)
      this.$router.push(link)
    }
  }
}
</script>
<style lang="less" scoped>
.navs {
  padding: 33px 0 55px;

  .navs-content {
    height: 70px;
    background: #ffffff;
    border-radius: 13px;
    display: flex;
    align-items: center;
    padding: 0 45px 0 5px;
  }

  .logo {
    width: 47px;
    height: 47px;
    cursor: pointer;
    margin-left: 10px;

    img {
      width: 100%;
      height: 100%;
      font-size: 0;
    }
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    overflow: hidden;

    .link-item {
      height: 37px;
      padding: 0 7px;
      margin-left: 30px;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.active {
        background-color: #777676;
        border-radius: 13px;
        color: #fff;
      }
    }
  }
}
</style>