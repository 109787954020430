import VueRouter from 'vue-router';
import Vue from 'vue';
// import Layout from '@/layout';
import Home from '@/views/home'
import ProductIntro from '@/views/product-intro';
import EquipmentIntro from '@/views/equipment-intro';
import Contact from '@/views/contact'
import CompanyIntro from '@/views/company-intro';
import Cases from '@/views/cases';

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

export const routes = [
    // {
    // path: '/',
    // component: Layout,
    // redirect: '/product',
    // children: [
    { path: '/', component: Home },
    { path: '/product', component: ProductIntro, name: '产品介绍' },
    { path: '/contact', component: Contact, name: '联系咨询' },
    { path: '/cases', component: Cases, name: '案例图片' },
    { path: '/equipment', component: EquipmentIntro, name: '设备简介' },
    { path: '/company', component: CompanyIntro, name: '公司简介' },
    // ]
    // },

];


const router = new VueRouter({
    routes
})

export default router;