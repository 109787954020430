<template>
    <div class="footer"></div>
</template>
<script>
export default {
    name: 'Footer-Component'
}
</script>
<style scoped lang="less">
.footer {
    height: 80px;
}
</style>